import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Tooltip from '../../Tooltip';
import { deviceFormSelector } from './selectors';
import { getModalsSelector } from '../../../redux-store/selectors/modals';
import { sensorsConfigurationFileSelector } from '../../../redux-store/selectors/devicesTable';
import { addNotification } from '../../../containers/NotificationGenerator/slice';
import i18n from '../../../i18n';

import './index.scss';

export const TokenString = (props) => {
  const dispatch = useDispatch();
  const {
    meta: { touched, error },
    languageToSync = '',
    showTooltip = '',
    deviceName,
    className,
    label,
    input,
    isShowFieldForGenerateToken,
    ...custom
  } = props;

  const modals = useSelector(getModalsSelector);
  const deviceForm = useSelector(deviceFormSelector);
  const sensorsConfigurationFile = useSelector(sensorsConfigurationFileSelector);

  const { values } = deviceForm || {};
  const { data, device_group } = values || {};
  const { secret = '', identifier = '', callbackUrl } = data || {};

  const isDisabled = !(secret && identifier && callbackUrl) && ['NIBE Uplink'].includes(deviceName);
  const currentSensorConfigurationFile = sensorsConfigurationFile?.find((sensor) => device_group?.includes(sensor?.device_group));
  const { flow } = currentSensorConfigurationFile || {};
  const { authorizationCodeLink } = flow || {};
  const { url, queryParameters } = authorizationCodeLink || {};

  const savedUrl = new URL(window.location.href);
  const state = { modals, deviceForm, savedUrl, currentSensorConfigurationFlow: flow };

  const recordId = `sensor_oauth2_${uuidv4()}`;

  const buildRedirectedURL = url && new URL(url);

  queryParameters?.forEach((query) => {
    const { name, type, value, field } = query;

    switch (type) {
      case 'value':
        buildRedirectedURL.searchParams.append(name, value);
        break;
      case 'field':
        buildRedirectedURL.searchParams.append(name, data?.[field]);
        break;
      default:
        buildRedirectedURL.searchParams.append(
          name,
          window.btoa(JSON.stringify({
            recordId,
            url: `${window.location.origin}/sensor/auth/oauth2`
          }))
        );
        break;
    }
  });

  const copyHandler = (text, result) => {
    if (result && text === input?.value) {
      dispatch(addNotification({ type: 'success', text: i18n.t('copied') }));
    }
  };

  const generateToken = () => {
    sessionStorage.setItem(recordId, JSON.stringify(state));

    const { href: redirectedURL } = buildRedirectedURL || {};
    const decodedRedirectedURL = decodeURIComponent(redirectedURL);

    window.location.replace(decodedRedirectedURL);
  };

  const onKeyDownHandler = (e) => {
    if (!(['KeyV', 'KeyC', 'KeyZ', 'KeyY'].includes(e.code) && e.ctrlKey)) {
      e.preventDefault();
    }
    if (['Backspace', 'Delete'].includes(e.code)) {
      input.onChange('');
    }
  };

  const onPasteHandler = (e) => {
    e.preventDefault();
    input.onChange(e.clipboardData.getData('text/plain'));
  };

  const clearToken = () => {
    input.onChange('');
  };

  //! Logic for Devices with hidden token field
  if (isShowFieldForGenerateToken) {
    return (
      <>
        <TextField
          variant="standard"
          label={i18n.exists(label) ? i18n.t(label) : label}
          {...input}
          {...custom}
          classes={{
            root: `customTextField tokenStringField ${className}`
          }}
        />
        <div className="d-flex flex-column">
          <button
            type="button"
            className="connectDeviceBtn"
            onClick={generateToken}
          >
            {i18n.t('connectDevice')}
          </button>
          {touched && (error || languageToSync) && (
            <div className="inputCustomWarning">{i18n.t(error)}</div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flags-select-label">
        {i18n.exists(label) ? i18n.t(label) : label}
        {' '}
        *
        {!!showTooltip && (
          <div className={`switch-tooltip ${input?.value && 'tooltipInSelect'}`}>
            <Tooltip title={i18n.t(showTooltip)} placement="left" />
          </div>
        )}
      </div>

      <TextField
        variant="standard"
        placeholder={i18n.exists(label) ? i18n.t(label) : label}
        {...input}
        {...custom}
        onKeyDown={onKeyDownHandler}
        onPaste={onPasteHandler}
        classes={{
          root: `customTextField tokenStringField ${className}`
        }}
        InputProps={{
          classes: {
            root: 'inputRoot',
            input: 'inputField'
          },
          endAdornment: (
            <div>
              {input?.value ? (
                <>
                  <button onClick={clearToken} className="delete-token-btn" type="button"><i className="la la-trash" /></button>
                  <CopyToClipboard onCopy={copyHandler} text={input?.value}>
                    <i className="la la-copy" />
                  </CopyToClipboard>
                </>
              ) : (
                <button
                  type="button"
                  className="generateTokenBtn"
                  onClick={generateToken}
                  disabled={isDisabled}
                >
                  {i18n.t('connectDevice')}
                </button>
              )}
            </div>
          )
        }}
        InputLabelProps={{ className: 'inputLabel' }}
      />
      {touched && (error || languageToSync) && (
        <div className="inputCustomWarning">{i18n.t(error)}</div>
      )}
    </>
  );
};

TokenString.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  showTooltip: PropTypes.string,
  deviceName: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  languageToSync: PropTypes.string,
  isShowFieldForGenerateToken: PropTypes.bool
};
