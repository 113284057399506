import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TabList, TabPanel, Tabs } from 'react-tabs';

import SignUpUser from './SignUpUser';
import ChangeEmail from './ChangeEmail';
import SignUpCompany from './SignUpCompany';
import { LanguageSelector } from '../../../components/UIKit';
import { Logo, Preloader } from '../../../components';
import { CreatingNewUserNoteWindow } from '../../../modals';

import { openModalWindow, closeModalWindow, CREATING_NEW_USER_NOTE_MODAL_ID } from '../../ModalWindow/slice';
import { getSignUpIsLoadingSelector } from '../../../redux-store/selectors/signUp';

import i18n from '../../../i18n';

import '../../../App.scss';
import '../index.scss';
import { APP_ID } from '../../../config';

/**
 * @description renders SignUp page depending on tab
 * @memberof module:SignUp
 * @extends Component
 */
const SignUpLayout = (props) => {
  const {
    sendSuccessfull,
    sentEmails,
    resendEmail,
    actions,
    changeEmail,
    sm_id,
    email,
    onSubmitChangeEmail,
    onSubmitUser,
    openQRScanner,
    onSubmitCompany
  } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(getSignUpIsLoadingSelector);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (APP_ID !== 'solarManagerEndUserApp') {
      dispatch(openModalWindow({ modalID: CREATING_NEW_USER_NOTE_MODAL_ID }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * redirect to some tab
   * @param {number} num - number of tab
   */
  const goToTab = useCallback(
    (num) => {
      if (num === 1) {
        dispatch(openModalWindow({ modalID: CREATING_NEW_USER_NOTE_MODAL_ID, data: null }));
      } else {
        setCurrentIndex(num);
      }
    },
    [dispatch]
  );

  const toggleCreatingNewUserNoteModal = useCallback(
    (num) => {
      setCurrentIndex(num);
      dispatch(closeModalWindow({ modalID: CREATING_NEW_USER_NOTE_MODAL_ID }));
    },
    [dispatch]
  );

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <CreatingNewUserNoteWindow toggleModal={toggleCreatingNewUserNoteModal} />
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1"
        id="m_login"
      >
        <div className="container">
          <div className="m-login__logo row justify-content-center align-items-center">
            <div className="mr-auto col-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <LanguageSelector className="topLanguageSelector" />
            </div>
          </div>
        </div>
        <div
          className={`m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center ${sendSuccessfull ? 'display-none' : ''}`}
        >
          <div className="m-login__container">
            <div className="row align-items-center">
              <div className="m-login__signin col-xl-6 col-lg-8 col-md-10 col-sm-10">
                {isLoading ? <Preloader /> : (
                  <>
                    <div className="m-login__head">
                      <h3 className="m-login__title">{APP_ID === 'solarManagerEndUserApp' ? i18n.t('creatingAccbtn') : i18n.t('createNewInstallerAccount')}</h3>
                    </div>
                    <div className="smu_pvi">
                      {changeEmail ? (
                        <div className="container-fluid">
                          <ChangeEmail
                            enableReinitialize
                            onSubmit={onSubmitChangeEmail}
                            initialValues={{
                              old_email: sentEmails.email
                            }}
                          />
                        </div>
                      ) : (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>

                          {APP_ID === 'solarManagerEndUserApp' ? (
                            <Tabs selectedIndex={currentIndex}>
                              <TabList>
                                <div className="container-fluid">
                                  <div className="">
                                    <ul className="nav nav-pills nav-fill nav-tabs-items" role="tablist">
                                      <div className="nav-item" onClick={goToTab.bind(this, 0)}>
                                        <a
                                          className={
                                        currentIndex === 0
                                          ? 'nav-link tab-heading active'
                                          : 'nav-link tab-heading'
                                      }
                                        >
                                          {i18n.t('SMUcaps')}
                                        </a>
                                      </div>
                                      <div className="nav-item" onClick={goToTab.bind(this, 1)}>
                                        <a
                                          className={
                                        currentIndex === 1
                                          ? 'nav-link tab-heading active'
                                          : 'nav-link tab-heading'
                                      }
                                        >
                                          {i18n.t('PVIcaps')}
                                        </a>
                                      </div>
                                    </ul>
                                  </div>
                                  <TabPanel>
                                    <div>
                                      <SignUpUser
                                        enableReinitialize
                                        initialValues={{ sm_id, email }}
                                        onSubmit={onSubmitUser}
                                        openQRScanner={openQRScanner}
                                      />
                                    </div>
                                  </TabPanel>
                                  <TabPanel>
                                    <div>
                                      <SignUpCompany onSubmit={onSubmitCompany} />
                                    </div>
                                  </TabPanel>
                                </div>
                              </TabList>
                            </Tabs>

                          ) : <SignUpCompany onSubmit={onSubmitCompany} />}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* begin thank you page */}
        <div
          className={`m-grid__item m-grid__item--fluid thank-you-cont row align-items-center ${sentEmails?.email && sendSuccessfull ? '' : 'display-none'}`}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-sm-8 thank-you-cont-blur">
                <h1>{i18n.t('thank')}</h1>
                <p>
                  {(i18n.t(resendEmail ? 'resentingMail' : 'sentingMail'))}
                  {' '}
                  <span className="thank_you_email">
                    {sentEmails?.email}
                  </span>
                  .
                  <br />
                  {i18n.t('activateLink')}
                </p>
                <p className="mail-links">
                  {!resendEmail && (
                    <button type="button" onClick={actions.resendToEmail}>
                      {i18n.t('resendMessage')}
                    </button>
                  )}
                  {!changeEmail && !resendEmail && <>&emsp;or&emsp;</>}
                  {!changeEmail && (
                    <a onClick={actions.changeEmailAction}>{i18n.t('changeYmail')}</a>
                  )}
                </p>
                <p className="warning-par">
                  {i18n.t('adviceMakeSure')}
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* end thank you page */}
      </div>
    </div>
  );
};

SignUpLayout.propTypes = {
  sendSuccessfull: PropTypes.bool,
  sentEmails: PropTypes.instanceOf(Object).isRequired,
  resendEmail: PropTypes.bool,
  actions: PropTypes.instanceOf(Object).isRequired,
  changeEmail: PropTypes.bool,
  sm_id: PropTypes.string,
  email: PropTypes.string,
  onSubmitChangeEmail: PropTypes.func.isRequired,
  onSubmitUser: PropTypes.func.isRequired,
  openQRScanner: PropTypes.func.isRequired,
  onSubmitCompany: PropTypes.func.isRequired
};

export default SignUpLayout;
